import './styles.scss';
export function DayClosed (){
    let opennedInDay = "";
    let opennedInHour = "";
    let textColor = "textOpenned";

    const datesClosed = [
        "2022-3-1",
        "2022-4-15",
        "2022-4-21",
        "2022-9-7",
        "2022-10-12",
        "2022-11-2",
        "2022-11-15",
        "2022-12-21",
        "2022-12-22",
        "2022-12-23",
        "2022-12-24",
        "2022-12-25",
        "2022-12-26",
        "2022-12-27",
        "2022-12-28",
        "2022-12-29",
        "2022-12-30",
        "2022-12-31",
        "2023-1-1",
        "2023-1-2",
        "2023-1-3",
        "2023-1-4",
        "2023-1-5",
        "2023-1-6",
        "2023-1-7",
        "2023-1-8",
        "2023-1-9",
        "2023-1-10",
        "2023-1-11",
        "2023-1-12",
        "2023-1-13",
        "2023-1-14",
        "2023-1-15",
        "2023-1-16",
        "2023-1-17",
        "2023-1-18",
    ];
    let closed = false;
    
    const momentRightNow = new Date(); //Data e Hora atual
    const weekDay = momentRightNow.getDay(); // Dia da semana de 0 a 6
    const fullYear = momentRightNow.getFullYear(); // Ano
    const month = momentRightNow.getMonth(); // Mes
    const day = momentRightNow.getDate();// Dia no mês
    const hour = momentRightNow.getHours(); // Hora
    const minutes = momentRightNow.getMinutes(); // Minutos
    const today = (
                    `${fullYear}
                    -${month + 1}
                    -${day}`
                    ).toString(); // data formato aaaa-mm-dd
    
    datesClosed.map((datas) => {
        if(datas === today) { 
            closed = true;
            opennedInDay="Fechado hoje";
            textColor = "textClosed";
        }
        return datas;
    })

    if(!closed){
        if (weekDay < 1 || weekDay > 5) {
            opennedInDay = "Fechado hoje"
            textColor = "textClosed";
        }else{
            if( 
                (
                    ((hour >= 8 && minutes >= 30)
                      || (hour >= 9)
                    ) 
                    && 
                    (hour < 12)
                ) 
                || 
                (
                    ((hour >= 13 && minutes >=30)
                        || (hour>=14)
                    ) 
                    && 
                    (hour < 18)
                )
            ) opennedInDay = "Aberto agora"; else {opennedInDay = "Fechado agora"; textColor = "textClosed";}

        }

        if((hour === 8 || hour === 13) && (minutes >= 14 && minutes < 30  )){
            opennedInHour = "Abre em breve...";
        }
        if((hour === 11 || hour === 17) && (minutes > 44 && minutes <= 59  )){
            opennedInHour = "Fecha em breve...";
        }
    }

    return (
        <div className='dayClosed'>
            <strong className={`${textColor}`}>{opennedInDay}</strong>
            <div>{opennedInHour}</div>
        </div>
    )
}
