import { Link } from 'react-router-dom'
import {FaWhatsapp, FaEnvelope, FaMapMarkerAlt, FaEdit, FaPhone} from 'react-icons/fa';
import { PageTitle } from '../../components/PageTitle';
//import { FaBullhorn, FaTimes} from 'react-icons/fa';

import logo from '../../assets/images/logo_500.png';

import './styles.scss';
//import { useState } from 'react';

export function Home(){
   /* const [showPopUp, setShowPopUp] = useState(true);
    function handleClosePopUp(){
        const closePopUpIsClicked = localStorage.getItem('@homePopup');

        if(closePopUpIsClicked == null){
            setShowPopUp(false);
            localStorage.setItem('@homePopup','ok');
        }
        return false;
    } */

    return (
        <div id="page-container" className="animator">
            { /* { showPopUp && localStorage.getItem('@homePopup') == null && (
            <div className="popup">
                <div className="aviso">
                    <span><FaBullhorn /> Aviso:</span>
                    <p>Entraremos em recesso a partir de 18/12/2023, retornamos aos trabalhos no dia 15/01/2024</p>
                </div>
                <strong onClick={handleClosePopUp}>
                    <FaTimes /> <span>Fechar</span>
                </strong>
            </div>
            )
            } */ }
            <PageTitle title="" />
            <div id="page-landing-content" className="container">
                <header>
                    <img src={logo} alt="VCRC Contabilidade" />
                </header>
                <main>
                <section>
                    <FaWhatsapp/>
                        <a target="_blanc" href="https://wa.me/5548998108597">+55 (48) 99810-8597</a>
                    </section>
                    <section>
                    <FaEnvelope/>
                        <a href="mailto:contato@vcrccontabilidade.com.br">contato@vcrccontabilidade.com.br</a>
                    </section>
                </main>
                <footer>
                    <Link to="/servicos" className="index-button">
                        <FaEdit />
                        <span>Serviços</span>
                    </Link>
                    <Link to="/visite" className="index-button">
                        <FaMapMarkerAlt />
                        <span>Visite-nos</span>
                    </Link>
                    <Link to="/contato" className="index-button">
                        <FaPhone />
                        <span>Contato</span>
                    </Link>
                </footer>
            </div>
        </div>
    )
}