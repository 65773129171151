import Leaflet from 'leaflet';

import mapMarkerImg from '../assets/images/map-marker.png';

const mapIcon = Leaflet.icon({
    iconUrl: mapMarkerImg,
  
    iconSize: [48, 54],
    iconAnchor: [24, 54],
    popupAnchor: [10, 2]
})

export default mapIcon;