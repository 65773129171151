import { Helmet } from "react-helmet";

type PageTitleTypes = {
    title: string
}

export function PageTitle( {title} : PageTitleTypes ) {
    return (
        <Helmet>
            <title>{title && `${title} | `}VCRC Contabilidade</title>
        </Helmet>
    )
}