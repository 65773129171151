import { Footer } from "../../components/Footer/Footer";
import PageHeader from "../../components/PageHeader";

import { Map, TileLayer, Marker } from 'react-leaflet'

import { FaMapMarkerAlt, FaInfoCircle, FaExternalLinkAlt } from 'react-icons/fa';
import mapIcon from "../../utils/mapIcon";

import './styles.scss';
import { DayClosed } from "../../components/DayClosed/DayClosed";

export function VisitUsPage(){
    return (
        <div id="visit-us" className="animator">
            <PageHeader title="Visite-nos" />
            <main>
                <section className="address-from-company">
                    <h1>Esse é o nosso endereço! 🗺️</h1>
                    <div>
                        <address>
                            <FaMapMarkerAlt />
                            Rua Monte Alegre, Nº 94<br />
                            Vila Alvorada <br />
                            Imbituba - Santa Catarina <br/>
                            CEP: 88780-000.
                            <DayClosed />
                        </address>
                        
                        <div className="map">
                            <Map
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                center={[-28.2223394,-48.6684651]}
                                zoom={15}
                                style={{ width: '100%', height: '100%' }}
                                dragging={true}
                                touchZoom={true}
                                zoomControl={true}
                                scrollWheelZoom={true}
                                doubleClickZoom={true}
                            >
                                <TileLayer
                                    url={`https://a.tile.openstreetmap.org/{z}/{x}/{y}.png`}
                                />
                                <Marker interactive={false} icon={mapIcon} position={[-28.2223394,-48.6684651]} />
                            </Map>
                            <footer>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://www.google.com.br/maps/dir/-28.2225287,-48.6689706/VCRC+Contabilidade+-+R.+1163,+94+-+Vila+Alvorada,+Imbituba+-+SC,+88780-000/@-28.2224361,-48.6711009,17z/data=!3m1!4b1!4m18!1m7!3m6!1s0x9526b553ce380d09:0xbcde9ade4650e15e!2sVCRC+Contabilidade!8m2!3d-28.2223394!4d-48.6681432!16s%2Fg%2F11mvbcv9sg!4m9!1m1!4e1!1m5!1m1!1s0x9526b553ce380d09:0xbcde9ade4650e15e!2m2!1d-48.6681432!2d-28.2223394!3e0?entry=ttu`}
                                >
                                    Ver rotas no Google Maps&nbsp;<FaExternalLinkAlt />
                                </a>
                            </footer>
                        </div>
                    </div>
                </section>
                <section className="information">
                    <p>
                        <FaInfoCircle />
                        <span>
                            <strong>Nós atendemos apenas com agendamento!</strong><br/>
                            Ligue ou envie mensagem para o número (48) 99810-8597 e agende seu horário.<br/> 
                            Acesse nossa página de <a href="/contato">contato</a> para outras formas de contato.
                        </span>
                    </p>
                </section>
            </main>
            <Footer />
        </div>
    );
}