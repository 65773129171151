import { FaHome, FaWhatsapp, FaEnvelope, FaInstagram } from 'react-icons/fa';
import './styles.scss';

export function Footer(){
    const year = new Date();
    return(
       <footer id="page-footer">
           <div className="page-footer-content">
                <div className="footer-copy">
                    <span>{`${year.getFullYear()}`} &copy; VCRC Contabilidade</span>
                    <span>Todos os direitos reservados</span>
                </div>
                <div className="footer-contact">
                        <a href="/">
                            <FaHome />
                        </a>
                        <a href="https://wa.me/5548998108597" target="_blanc">
                            <FaWhatsapp />
                        </a>
                        <a href="mailto:contato@vcrccontabilidade.com.br">
                            <FaEnvelope />
                        </a>
                        <a href="https://www.instagram.com/vcrc_contabilidade/" target="_blanc">
                            <FaInstagram />
                        </a>
                </div>
                <div className="footer-dev">
                    Desenvolvido por&nbsp;<a href='mailto:fabianovcr@gmail.com'>FC</a>
                </div>
           </div>
       </footer>
    )
}