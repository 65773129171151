import {BrowserRouter, Route, Switch} from 'react-router-dom';

//Importando as paginas para as rotas
import { Home } from './pages/Home/index';
import { ServicesPage } from './pages/ServicesPage';
import { VisitUsPage } from './pages/VisitUsPage';
import { ContactPage } from './pages/ContactPage';

import './global.scss';

import 'leaflet/dist/leaflet.css';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/servicos" component={ServicesPage} />
          <Route path="/visite" component={VisitUsPage} />
          <Route path="/contato" component={ContactPage} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
