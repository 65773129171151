import PageHeader from '../../components/PageHeader';
import { Footer } from '../../components/Footer/Footer';
import {
    FaStore,
    FaCalculator,
    FaFile,
    FaFileInvoiceDollar,
    FaFileUpload,
    FaFileInvoice,
    FaFileSignature,
    FaCommentDots,
    FaWhatsapp
} from 'react-icons/fa';

import imgBanner from '../../assets/images/banner.svg'

import './styles.scss';

export function ServicesPage() {
    return (
        <div id="page-services" className="animator">
            <PageHeader title="Serviços" />
            <main>
                <div className="main">
                    <section>
                        <h1 className="title">Um pouco do que fazemos por aqui...</h1>
                        <p>
                            Muitos não sabem, mas a contabilidade é de extrema importância para as empresas!
                            Ela possibilita ao empresário maior controle sobre o seu negócio.
                            Conheça um pouco dos nossos serviços:
                        </p>

                        <div className="service-content">
                            <ul>
                                <li>
                                    <FaStore />
                                    <span>Abertura de empresa</span>
                                </li>
                                <li>
                                    <FaCalculator />
                                    <span>Escrituração contábil</span>
                                </li>
                                <li>
                                    <FaFile />
                                    <span>Processamento da folha de pagamentos</span>
                                </li>
                                <li>
                                    <FaFileUpload />
                                    <span>Entrega de obrigações acessórias</span>
                                </li>
                                <li>
                                    <FaFileInvoiceDollar />
                                    <span>Emissão de guias de impostos</span>
                                </li>
                                <li>
                                    <FaFileInvoice />
                                    <span>Emissão de CNDs</span>
                                </li>
                                <li>
                                    <FaFileSignature />
                                    <span>Declaração do Imposto de Renda</span>
                                </li>
                                <li>
                                    <FaCommentDots />
                                    <span>E muito mais...</span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="contact-us">
                    <section className="contact-us-content">
                        <div className="contents">
                            <span>
                                Empreender é uma missão nobre porém dificil,
                                surgem muitas dúvidas e burocracias. 
                                A VCRC Contabilidade vê o empreendedorismo como uma forma
                                de gerar oportunidades e desenvolvimento e está pronta para ajudar
                                você a ter sucesso com o seu negócio!
                            </span>
                            <div className="services-link-whatsapp">
                                <a target="_blanc" href="https://wa.me/5548998108597">
                                <FaWhatsapp />
                                Fale conosco pelo Whatsapp!
                                </a>
                            </div>
                        </div>
                        <div className="banner">
                            <img src={imgBanner} alt="Banner" />
                            <a href="https://pt.vecteezy.com/vetor-gratis/finan%C3%A7a" target="_blanc">Fonte da imagem: Vecteezy</a>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}