import { Link } from 'react-router-dom';

import { FaHome, FaEdit, FaMapMarkerAlt, FaPhone } from 'react-icons/fa'

import logoImg from '../../assets/images/logo_blue_small.png';

import './styles.scss';
import { PageTitle } from '../PageTitle';

interface PageHeaderProps {
    title: string;
    description?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({title, description}: PageHeaderProps) => {
    return(
        <header id="page-header">
            <PageTitle title={title} />
            <div className="header-content">
                <div>
                    <img src={logoImg} alt="VCRC Contabilidades" className="logo" />
                </div>
                <nav>
                    <Link to="/">
                        <FaHome /><strong>Home</strong>
                    </Link>
                    
                    <Link to="/servicos" className={title === "Serviços" ? "selected-link" : ''}>
                        <FaEdit /><strong>Serviços</strong>
                    </Link>

                    <Link to="/visite" className={title === "Visite-nos" ? "selected-link" : ''}>
                        <FaMapMarkerAlt /><strong>Visite</strong>
                    </Link>

                    <Link to="/contato" className={title === "Contato" ? "selected-link" : ''}>
                        <FaPhone /><strong>Contato</strong>
                    </Link>
                </nav>
            </div>
        </header>
    )
}

export default PageHeader;